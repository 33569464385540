import { nbProductMessages } from '../../../messages/product.nb';

/*
 * No Customer Translations
 */
export const nbCustomMessages = {
  ...nbProductMessages,
  construo: {
    ...nbProductMessages.construo,
    footer: {
      ...nbProductMessages.construo.footer,
      link1Label: 'www.klp.no',
      link1URL: 'https://www.klp.no',
      link2Label: '',
      link2URL: '',
      link3Label: '',
      link3URL: '',
      text: 'KLP - Kommune- og Helse-Norges eget pensjonsselskap',
    },
  },
};
