import { config as productConfig, ConfigProps } from '../../resources/config';

export const config: ConfigProps = {
  ...productConfig,
  portfolios: {
    ...productConfig.portfolios,
    performanceGraph: {
      ...productConfig.portfolios.performanceGraph,
      horizontalLineColor: '#3d3d3d',
    },
  },
};
